<template>
  <connection-success :close-action="closeAction" :buttons="buttons" class="obk-connection-suggestion">
    <template v-slot:title>
      <div>רק עוד כמה דקות וסיימנו</div>
    </template>
    <template v-slot:subtitle>
      <div>
        <span>בחיבור ישיר ל{{ currentNonMigratedBankCC.bankName }} המערכת לא מקבלת את פירוט עסקאות האשראי שלך.</span>
        <span class="bold">כדי להמשיך לקבל תזרים מלא, צריך לחבר את החשבון של {{ currentNonMigratedBankCC.displayName }}.</span>
      </div>
    </template>
  </connection-success>
</template>

<script>
import ConnectionSuccess from '@/base-components/ConnectionSuccess';
import AddCredentialsFlow
  from '@/pages/responsive-pages/authenticated/navigation-pages/credentials-settings/add-credentials-flow/AddCredentialsFlow';
import { mapActions, mapState } from 'vuex';
import Segment from '@/Segment';
import { getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';

export default {
  name: 'OBKConnectionSuggestion',
  components: {
    ConnectionSuccess,
  },
  props: {
    closeAction: {
      type: Function,
      required: true,
    },
    source: {
      type: Object,
      required: true,
    },
  },
  created() {
    Segment.trackUserGot('ConnectMissingBankCCsSuggestion_Entered', {
      sourceName: this.sourceName,
    });
  },
  computed: {
    ...mapState('credentials', ['missingBankCCAccountsAfterOBKMigration']),
    currentNonMigratedBankCC() {
      if (!this.missingBankCCAccountsAfterOBKMigration.length) {
        return undefined;
      }
      const bankCC = this.missingBankCCAccountsAfterOBKMigration[0];
      return { ...bankCC, displayName: getSourceConfigurationBySourceName(bankCC.source).displayName };
    },
    buttons() {
      return [
        {
          title: `להמשך החיבור ב${this.currentNonMigratedBankCC.displayName}`,
          action: this.openCCConnectionFlow,
          size: 'small',
        },
      ];
    },
    sourceName() {
      return this.source.sourceName;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal', 'closeAllModals']),
    openCCConnectionFlow() {
      Segment.trackUserGot('OBKMigrationBankCCWarning_ConnectClicked', {
        sourceName: this.sourceName,
        hasBankCCAccount: !!this.currentNonMigratedBankCC,
      });
      this.closeAllModals();
      this.openModal({
        component: AddCredentialsFlow,
        props: {
          sourceNameToAdd: this.currentNonMigratedBankCC.source,
        },
        popupAlignment: 'full-screen',
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.bold {
  font-weight: bold;
}

</style>
