<template>
  <connection-success :close-action="closeAction" :buttons="buttons" class="obk-connection-suggestion">
    <template v-slot:title>
      <div>רוצה להחליף בקלות גם את החשבונות האחרים שלך לחיבור ישיר?</div>
    </template>
    <template v-slot:subtitle>
      <div>
        ככה לא צריך לעדכן סיסמה כל כמה חודשים, אלא רק לחדש את ההסכמה פעם בשלוש שנים.
      </div>
    </template>
  </connection-success>
</template>

<script>
import ConnectionSuccess from '@/base-components/ConnectionSuccess';
import { mapActions } from 'vuex';
import router from '@/router';
import Segment from '@/Segment';

export default {
  name: 'OBKConnectionSuggestion',
  components: {
    ConnectionSuccess,
  },
  props: {
    closeAction: {
      type: Function,
      required: true,
    },
    sourceType: {
      type: String,
      required: true,
    },
    source: {
      type: Object,
      required: true,
    },
  },
  created() {
    Segment.trackUserGot('OBKConnectionSuggestionAfterSuccess_Entered', {
      sourceName: this.sourceName,
    });
  },
  computed: {
    buttons() {
      return [
        {
          title: 'כן, אני רוצה להחליף',
          action: this.redirectToCredentialsPage,
          size: 'small',
        },
        {
          title: 'חזרה לתזרים',
          action: this.redirectToCashflow,
          size: 'small',
          variant: 'secondary',
        },
      ];
    },
    sourceName() {
      return this.source.sourceName;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal', 'closeAllModals']),
    redirectToCredentialsPage() {
      Segment.trackUserGot('OBKMigrationBankCCWarning_ConnectClicked', {
        sourceName: this.sourceName,
        hasBankCCAccount: !!this.currentNonMigratedBankCC,
      });
      this.closeAllModals();
      router.push({ path: '/credentials' });
    },
    redirectToCashflow() {
      Segment.trackUserGot('OBKMigrationBankCCWarning_ConnectLaterClicked', {
        sourceName: this.sourceName,
        hasBankCCAccount: !!this.currentNonMigratedBankCC,
      });
      this.closeAllModals();
      router.push({ path: '/home' });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
