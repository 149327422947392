<template>
  <credentials-with-async-fallback
      :is-loading="isLoading"
      :async-timeout-reached="asyncTimeoutReached"
      :source-name="updatedCredentialsSourceName"
      :on-timeout="onTimeout"
      :on-success="onSuccess"
      :loading-start-time="loadingStartTime"
      class="content-wrapper">
  </credentials-with-async-fallback>
</template>

<script>
import CredentialsWithAsyncFallback from '@/pages/responsive-pages/authenticated/onboard2/CredentialsWithAsyncFallback';
import { mapState, mapActions, mapGetters } from 'vuex';
import Segment from '@/Segment';
import router from '@/router';
import CredentialsConnectionSuccess
  from '@/pages/responsive-pages/authenticated/onboard2/steps/create-credentials-flow/CredentialsConnectionSuccess';
import { getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';
import OBKConnectionSuggestion from '@/pages/responsive-pages/non-authenticated/open-banking/OBKConnectionSuggestion';
import ConnectMissingBankCCsSuggestion
  from '@/pages/responsive-pages/non-authenticated/open-banking/ConnectMissingBankCCsSuggestion';
import ConnectMissingRegularCCsSuggestion
  from '@/pages/responsive-pages/non-authenticated/open-banking/ConnectMissingRegularCCsSuggestion';
import { LOCAL_STORAGE_KEY_UPDATE_OLD_CREDENTIALS } from '@/constants/credentials';
import moment from 'moment';

export default {
  name: 'LoadingOBKCredentials',
  components: {
    CredentialsWithAsyncFallback,
  },
  async created() {
    await this.fetchLoadingCredentialsState();
    await this.handleLoadingState();
  },
  computed: {
    ...mapState('websocket', ['socketMessage']),
    ...mapState('credentials', ['isLoading', 'updatedCredentialsSourceName', 'loadingStartTime', 'asyncTimeoutReached',
      'connectedConsentMetadata', 'missingBankCCAccountsAfterOBKMigration', 'missingRegularCCAccountsAfterOBKMigration']),
    ...mapGetters('credentials', ['getCredsBankCCAccounts', 'validNonOBkCredentials']),
    source() {
      return getSourceConfigurationBySourceName(this.updatedCredentialsSourceName);
    },
    credsMigratedFromPasswords() {
      return !!this.closedBankingCredentialsId;
    },
    closedBankingCredentialsId() {
      return this.connectedConsentMetadata?.closedBankingCredentialsId;
    },
    bankCCAccounts() {
      return this.getCredsBankCCAccounts(this.closedBankingCredentialsId);
    },
    nonOBKCredsExistsAfterMigration() {
      return this.credsMigratedFromPasswords && this.validNonOBkCredentials?.length;
    },
    hasNonMigratedRegularCCs() {
      return this.missingRegularCCAccountsAfterOBKMigration.length;
    },
    hasNonMigratedBankCCs() {
      return this.missingBankCCAccountsAfterOBKMigration.length;
    },
    shouldSuggestOBKMigration() {
      return this.nonOBKCredsExistsAfterMigration
      || this.hasNonMigratedBankCCs || this.hasNonMigratedRegularCCs;
    },
    shouldMoveToAdvisoryUpdateCredsPage() {
      // moving back to "advisory update creds" page in case the customer gave consent from that page until 5 minutes ago.
      // In case 5 minutes passed, it probably means that the current connection is not from "advisory update creds" page.
      const oldCredentialsConnectionTime = localStorage.getItem(LOCAL_STORAGE_KEY_UPDATE_OLD_CREDENTIALS);
      return moment(oldCredentialsConnectionTime).diff(moment(), 'minutes') < 5;
    },
  },
  methods: {
    ...mapActions('credentials', ['fetchLoadingCredentialsState', 'handleLoadingState', 'fetchCredentials']),
    ...mapActions('modalRootStore', ['openModal', 'closeModal', 'closeAllModals']),
    async onTimeout() {
      Segment.trackUserGot('TimeoutOnAsyncFallbackFromOBK', { sourceName: this.sourceName });
      console.log('timeout');
      if (this.shouldMoveToAdvisoryUpdateCredsPage) {
        this.navigateToUpdateOldCredsPage();
      } else {
        await router.push({ path: '/home' });
      }
    },
    async onSuccess() {
      await this.fetchCredentials();
      this.openModal({
        component: CredentialsConnectionSuccess,
        props: {
          sourceType: this.source.type,
          sourceName: this.source.sourceName,
          closeAction: this.closeOnSuccess,
        },
        popupAlignment: 'full-screen',
      });
      Segment.trackUserGot('SuccessOnAsyncFallbackFromOBK', { sourceName: this.sourceName });
      await router.push({ path: '/credentials' });
    },
    openSuggestOBKConnectionPopup() {
      if (this.hasNonMigratedRegularCCs) {
        this.openModal({
          component: ConnectMissingRegularCCsSuggestion,
          popupAlignment: 'full-screen',
        });
      } else {
        this.openModal({
          component: this.hasNonMigratedBankCCs ? ConnectMissingBankCCsSuggestion : OBKConnectionSuggestion,
          props: {
            sourceType: this.source.type,
            source: this.source,
            closeAction: this.closeAllModals,
          },
          popupAlignment: 'full-screen',
        });
      }
    },
    closeOnSuccess() {
      if (this.shouldSuggestOBKMigration) {
        this.openSuggestOBKConnectionPopup();
        return;
      }

      if (this.shouldMoveToAdvisoryUpdateCredsPage) {
        this.navigateToUpdateOldCredsPage();
      }

      this.closeModal();
    },
    navigateToUpdateOldCredsPage() {
      router.push({ path: '/mislaka-consent', query: { jumpToRenewCreds: true } });
    },
  },
  watch: {
    async socketMessage(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.fetchLoadingCredentialsState();
      }
    },
  },
};
</script>
