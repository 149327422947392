<template>
    <full-screen-modal class="connect-missing-regular-ccs-suggestion"
                       :buttons="buttons"
                       :show-riseup-logo="true">
      <template v-slot:content>
        <div class="padding-ml">
          <img :src="require('@/assets/icons/missing-card.svg')" />
          <div class="title ri-font-22 ri-font-weight-bold margin-top-l">רגע, לא חיברת את כל הכרטיסים!</div>
          <div class="description ri-font-16">
            <span>ההסכמה אושרה, אבל היא לא כוללת חלק מהכרטיסים שלך שהיו מחוברים קודם. כדי לקבל תזרים מלא,</span>
            <span class="ri-font-weight-bold">צריך לתת הסכמה מחדש ולסמן בה את הכרטיסים שחסרים.</span>
          </div>
          <missing-credit-cards :missing-card-numbers="cardNumbers" class="margin-top-l"/>
          <missing-creds-partner-wraning-tooltip class="margin-top-l" />
        </div>
      </template>
    </full-screen-modal>
</template>

<script>
import _ from 'lodash';
import router from '@/router';
import FullScreenModal from '@/base-components/FullScreenModal';
import { getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';
import ConnectMissingRegularCCsConnectionDecision
  from '@/pages/responsive-pages/non-authenticated/open-banking/ConnectMissingRegularCCsConnectionDecision';
import { mapActions, mapGetters, mapState } from 'vuex';
import Segment from '@/Segment';
import MissingCreditCards from '@/pages/responsive-pages/non-authenticated/open-banking/MissingCreditCards';
import MissingCredsPartnerWraningTooltip
  from '@/pages/responsive-pages/non-authenticated/open-banking/MissingCredsPartnerWraningTooltip';

export default {
  name: 'ConnectMissingRegularCCsSuggestion',
  components: { MissingCredsPartnerWraningTooltip, MissingCreditCards, FullScreenModal },
  created() {
    Segment.trackUserGot('ConnectMissingRegularCCsSuggestions_Entered');
  },
  computed: {
    ...mapState('credentials', ['missingRegularCCAccountsAfterOBKMigration', 'credentials']),
    ...mapGetters('credentials', ['getCredsByCredentialsId']),
    missingSource() {
      const missingAccountsBySource = _.chain(this.missingRegularCCAccountsAfterOBKMigration)
        .groupBy('source')
        .value();
      const firstSource = _.keys(missingAccountsBySource)[0];
      return missingAccountsBySource[firstSource];
    },
    sourceName() {
      return getSourceConfigurationBySourceName(this.missingSource[0].source).displayName;
    },
    cardNumbers() {
      return _.map(this.missingSource, source => source.accountNumberPiiValue);
    },
    missingSourceCreds() {
      return this.getCredsByCredentialsId(this.missingSource[0].credentialsId);
    },
    buttons() {
      return [{
        title: this.cardNumbers.length > 1 ? 'לחבר את הכרטיסים החסרים' : 'לחבר את הכרטיס החסר',
        action: this.openConnectionDecisionPopup,
        size: 'small',
      },
      {
        title: 'להמשיך לתזרים',
        action: this.redirectToCashflow,
        variant: 'secondary',
        size: 'small',
      }];
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeAllModals']),
    openConnectionDecisionPopup() {
      Segment.trackUserGot('ConnectMissingRegularCCsSuggestions_Connect_Clicked',
        { missingRegularCCAccountsAfterOBKMigration: this.missingRegularCCAccountsAfterOBKMigration });
      this.openModal({
        component: ConnectMissingRegularCCsConnectionDecision,
        props: {
          source: this.missingSourceCreds,
          missingAccount: this.cardNumbers[0],
        },
        showLastModal: true,
      });
    },
    redirectToCashflow() {
      Segment.trackUserGot('ConnectMissingRegularCCsSuggestions_Redirect_To_Cashflow_Clicked');
      this.closeAllModals();
      router.push({ path: 'sr' });
    },
  },
};
</script>

<style lang="scss" scoped>
.connect-missing-regular-ccs-suggestion {

  .title{
    line-height: 28px;
  }

  .description{
    line-height: 24px;
  }
}

</style>
